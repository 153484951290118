import React, { useEffect, useState } from 'react'
import { FluidObject } from 'gatsby-image'
import { graphql } from 'gatsby'
import { OutboundLink } from 'gatsby-plugin-google-analytics'
import { useInView } from 'react-intersection-observer'
import styled from '@emotion/styled'

import { Product } from '../types'
import {
  sizes,
  lineHeight,
  colors,
  widths,
  durations,
  borderRadius
} from '../styles/variables'
import ContentFooter from '../components/ContentFooter'
import Gallery from '../components/Gallery'
import Heading from '../components/Heading'
import IndexLayout from '../layouts'
import ProductTable from '../components/ProductTable'
import SummaryItem from '../components/SummaryItem'

interface IndexPageImage {
  id: string
  childImageSharp: {
    fluid: FluidObject
  }
}

interface IndexPageProps {
  data: {
    allProductsJson: {
      edges: {
        node: Product
      }[]
    }
    dataJson: {
      about: IndexPageImage[]
      statement: IndexPageImage[]
      shitsurindo: IndexPageImage[]
    }
    imageInfoOnlineStore: IndexPageImage
    imageInfoOfflineStore: IndexPageImage
  }
}

export const query = graphql`
  query IndexPage {
    allProductsJson {
      edges {
        node {
          series
          form
          finish
          retail
          image {
            childImageSharp {
              fixed(width: 360, height: 360, quality: 90) {
                ...GatsbyImageSharpFixed_withWebp_noBase64
              }
            }
          }
          price
          size
          material
        }
      }
    }
    dataJson {
      about {
        id
        childImageSharp {
          fluid(maxWidth: 760, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      statement {
        id
        childImageSharp {
          fluid(maxWidth: 760, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      shitsurindo {
        id
        childImageSharp {
          fluid(maxWidth: 760, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
    imageInfoOnlineStore: file(
      relativePath: { eq: "images/infoOnlineStore.jpg" }
    ) {
      id
      childImageSharp {
        fluid(maxWidth: 1300, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    imageInfoOfflineStore: file(
      relativePath: { eq: "images/infoOfflineStore.jpg" }
    ) {
      id
      childImageSharp {
        fluid(maxWidth: 1300, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`

const IndexPage: React.FC<IndexPageProps> = ({ data }) => {
  const products = data.allProductsJson.edges.map(({ node }) => node)
  const [blankRef, inView] = useInView({ threshold: 1 })
  const [isPageTop, setIsPageTop] = useState<boolean>(true)

  useEffect(() => {
    const updateScrollState = () => {
      setIsPageTop(pageYOffset <= 0)
    }

    addEventListener('scroll', updateScrollState)
    addEventListener('resize', updateScrollState)
    updateScrollState()

    return () => {
      removeEventListener('scroll', updateScrollState)
      removeEventListener('resize', updateScrollState)
    }
  }, [])

  return (
    <IndexLayout intro={inView || isPageTop}>
      <Blank ref={blankRef} />
      <Chapter id="about">
        <Section>
          <Constraint>
            <Heading category="About">RIN&CO.</Heading>
          </Constraint>
          <Lead id="statement">
            <Gallery images={data.dataJson.statement} />
          </Lead>
          <ConstraintTight>
            <PoetryGroup>
              <p>北の地のものづくり</p>
              <p>
                北の地の冬は雪深く、光も少ない。 <br />
                家の中で過ごす時間が自然と長くなるため、 <br />
                ふだんの暮らしを豊かにする <br />
                さまざまな知恵が育まれてきました。
              </p>
              <p>
                心地よい空間をつくること。 <br />
                必要なものだけを選び、長く使うこと。 <br />
                手づくりの温かみを大切にすること。 <br />
                自然を身近に感じること。
              </p>
              <p>
                わたしたちRIN&CO.が <br />
                大切にしているのは <br />
                北の地で考えられ、 <br />
                北の地で受け継がれてきた、 <br />
                北の地ならではのものづくり。
              </p>
              <p>
                暮らしに自然と溶け込み、 <br />
                毎日をより上質に、 <br />
                より豊かにする道具を <br />
                つくり続けること。 <br />
                それがわたしたちの仕事と考えています。
              </p>
              <p className="isMarginTopLoose">暮らしにひとつ、北のものを。</p>
            </PoetryGroup>
          </ConstraintTight>
          <Lead>
            <Gallery images={data.dataJson.about} />
          </Lead>
          <ConstraintTight>
            <PoetryGroup>
              <p>RIN&CO.とは</p>
              <p>
                <span className="isLarge">
                  Reason <br />
                  In <br />
                  NorthLand
                </span>
                <br />
                北陸の地である理由
              </p>
              <p>
                <span className="isLarge">&Co.</span> <br />
                産地、地域の仲間たち
              </p>
            </PoetryGroup>
            <PoetryGroup>
              <p>
                福井県鯖江市河和田。 <br />
                三方を山に囲まれ、 <br />
                漆の街として知られるこの地は <br />
                曇天の多い、湿潤な地域です。 <br />
                年間の降雨日数は全国でも有数で、 <br />
                冬にはしんしんと雪が降り続け、 <br />
                寒さも身に応えます。 <br />
                人にとっては厳しいこの環境も、 <br />
                漆が硬化するには理想的といえます。 <br />
                漆琳堂は長年この地域で <br />
                ものづくりを続けてきた企業として、 <br />
                これからも地元や北陸の職人とともに <br />
                この地ならではの技術を生かした <br />
                魅力的な商品づくりを行ない、 <br />
                日本全国へお届けします。
              </p>
            </PoetryGroup>
          </ConstraintTight>
        </Section>
      </Chapter>

      <Chapter id="products">
        <Section>
          <Constraint>
            <Heading category="Products">越前硬漆</Heading>
            <Lead>
              <Text>
                <p>
                  漆が最も硬くなるのは塗り上げてから100年後ともいわれ、完全硬化には長い年月を要します。「越前硬漆」は福井県、福井大学との産学官の連携によって堅い塗膜を実現した、食器洗い機にも耐えうる漆。現代の食生活にも沿うように独自の形状と美しい彩りに仕上げており、日用の食器としてお使いいただけます。
                </p>
              </Text>
            </Lead>
          </Constraint>
        </Section>

        <Section>
          <Constraint>
            <Text>
              <p>
                <strong className="isTitle">真塗り技法</strong>
                一度の塗りで塗膜を仕上げる「真塗り技法」は漆を何度もろ過して塵をていねいに取り除き、刷毛目を残さないように塗り上げるため、熟練の技術を必要とします。
              </p>
            </Text>
          </Constraint>
          <StyledProductTable
            series="primary"
            forms={['ds', 'dm', 'dl', 't', 'w', 'f', 'fm', 'fl', 'fw']}
            finishes={['b']}
            products={products}
          />
        </Section>

        <Section>
          <Constraint>
            <Text>
              <p>
                <strong className="isTitle">刷毛目技法</strong>
                手塗りによる刷毛目を活かす「刷毛目技法」は塗り上げた後に塗り直しをすることができないため、卓越した技術を必要とします。
              </p>
            </Text>
          </Constraint>
          <StyledProductTable
            series="primary"
            forms={['ds', 'dm', 'dl', 't', 'w', 'f', 'fm', 'fl', 'fw']}
            finishes={['r3', 'r2', 'r1', 'i3', 'i2', 'i1', 'n3', 'n2', 'n1']}
            products={products}
          />
        </Section>

        <Section>
          <Constraint>
            <Heading category="Products">白九谷</Heading>
            <Lead>
              <Text>
                <p>
                  石川県を代表する磁器であり、伝統工芸である九谷焼。その歴史は500年前にまで遡るといわれ、古くから地域に愛されてきました。最大の特徴といえるのが華麗な絵付けで、江戸時代には加賀藩前田家に重宝され、現在では割烹食器として金沢の料亭などで広く使われています。漆の世界でも、漆を保存する容器は長年、無地の白い九谷焼が用いられてきました。その理由は水分を含有することなく、硬い性質があること。そして何よりも、互いの産地が地理的に近いこと。強度に優れ、透けるように白い九谷焼を越前硬漆と同じサイズでつくり上げたのが「白九谷」です。
                </p>
              </Text>
            </Lead>
          </Constraint>
          <StyledProductTable
            series="kutani"
            forms={['ds', 'meshiwan-s', 'meshiwan-m']}
            finishes={['k']}
            products={products}
          />
        </Section>

        <Section>
          <Constraint>
            <Heading category="Products">越前木工</Heading>
            <Lead>
              <Text>
                <p>
                  漆器は木地、下地、塗り、蒔絵、の4つの工程の分業で製作されます。お椀や茶托などを「丸物」と呼ぶのに対し、お重や角盆などは「角物」と呼ばれます。「丸物木地」はろくろを使って形を削り出す技法で、薄挽きや加飾挽きなどの高度な技術を要します。「角物木地」は厚さを整えた板を組み合わせることで製作され、緻密な計算と精度の高い技術を要します。どちらの工法にも木を知り、刃を研ぐという日本古来の傑出した加工技術や熟練の職人技がしっかりと継承され、その技によって生まれる木目の美しさが越前木工の特徴です。
                </p>
              </Text>
            </Lead>
          </Constraint>
          <StyledProductTable
            series="mokko"
            forms={[
              'sumikiribon-s',
              'sumikiribon-l',
              '8sun-marubon',
              '9sun-marubon',
              'hashi-shikaku',
              'hashi-hakkaku'
            ]}
            finishes={['mokko']}
            products={products}
          />
        </Section>
      </Chapter>

      <Chapter>
        <Section id="shitsurindo">
          <Constraint>
            <Heading category="Produced by">漆琳堂</Heading>
          </Constraint>
          <Lead>
            <Gallery images={data.dataJson.shitsurindo} />
          </Lead>
          <Constraint>
            <Lead>
              <Text>
                <p>
                  1793年に創業した漆琳堂は創業者内田忠治郎の時代から現在の8代目内田徹に渡るまで、一貫して伝統工芸品「越前漆器」を継承して参りました。「漆器を日常に」という想いのもと、業務用及び一般用漆器の製造、販売、修理を行なっております。
                  <br />
                  <OutboundLink
                    href="https://shitsurindo.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    漆琳堂ウェブサイトへ
                  </OutboundLink>
                </p>
              </Text>
            </Lead>
          </Constraint>
        </Section>
      </Chapter>

      <Chapter id="info">
        <Constraint>
          <Group>
            <SummaryItem
              image={data.imageInfoOnlineStore}
              title="RIN&CO. ONLINE STORE"
            >
              <Text>
                <OutboundLink
                  href="https://shitsurindo-onlinestore.com/?category_id=5ff67c21da019c21ec96e0f5"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  オンラインストアへ
                </OutboundLink>
              </Text>
            </SummaryItem>
          </Group>
          <Group>
            <SummaryItem
              image={data.imageInfoOfflineStore}
              title="漆琳堂直営店"
            >
              <Text>
                〒916-1221 福井県鯖江市西袋町701（
                <OutboundLink
                  href="https://goo.gl/maps/Jbv7rtBBtvsWsqds6"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Google Maps
                </OutboundLink>
                ） <br />
                TEL：<a href="tel:0778650630">0778-65-0630</a> <br />
                直営店営業時間：10:00 - 17:00 （不定休{' '}
                <a
                  href="https://www.instagram.com/shitsurindo/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  ※SNSでお知らせ
                </a>
                ）
              </Text>
            </SummaryItem>
          </Group>
        </Constraint>
      </Chapter>

      <ContentFooter />
    </IndexLayout>
  )
}

export default IndexPage

const Blank = styled.div`
  @media (min-width: ${widths.mobileDesktop}px) {
    height: 100vh;
  }
`

const Text = styled.div`
  color: ${colors.black50};
  line-height: ${lineHeight.loose};

  p {
    margin: 2em 0;
  }

  a {
    border-bottom: 1px solid ${colors.link25};
    color: ${colors.link};
    text-decoration: none;
    transition: border-color ${durations.normal}s, color ${durations.normal}s;
  }

  @media (hover: hover) {
    a:hover {
      border-bottom-color: ${colors.black50};
      color: ${colors.black};
    }
  }

  strong.isTitle {
    color: ${colors.black75};
    font-weight: normal;
  }

  strong.isTitle::after {
    content: '';
    display: inline-block;
    height: 1px;
    position: relative;
    width: 1em;
  }

  .isLarge {
    font-size: ${sizes.larger1}em;
  }
`

const Chapter = styled.div`
  padding: 10rem 0;
  position: relative;

  & + &::before {
    background: linear-gradient(
      to right,
      ${colors.black0},
      ${colors.black10},
      ${colors.black0}
    );
    content: '';
    display: block;
    height: 1px;
    left: calc(50% - ${((sizes.item + 1) * 2 - 1) * 0.5}rem);
    max-width: 100%;
    position: absolute;
    top: 0;
    width: ${(sizes.item + 1) * 2 - 1}rem;
  }
`

const Section = styled.section`
  & + & {
    margin-top: 10rem;
  }
`

const Group = styled.div`
  & + & {
    margin-top: 2.5rem;
  }
`

const Constraint = styled.div`
  margin: 0 auto;
  max-width: calc(100% - 6rem);
  width: ${(sizes.item + 1) * 3 - 1}rem;
`

const ConstraintTight = styled(Constraint)`
  max-width: calc(100% - 8rem);
  width: ${(sizes.item + 1) * 2 - 1}rem;
`

const Lead = styled.div`
  margin-top: 2.5rem;
`

const PoetryGroup = styled(Text)`
  margin: 5rem 0;

  .isMarginTopLoose {
    margin-top: 4em;
  }
`

const StyledProductTable = styled(ProductTable)`
  margin-top: 5rem;
`

const ComingSoon = styled.div`
  align-items: center;
  border-radius: ${borderRadius}rem;
  border: 1px solid ${colors.black10};
  box-sizing: border-box;
  color: ${colors.black25};
  display: flex;
  font-size: ${sizes.larger1}em;
  height: ${sizes.item}rem;
  justify-content: center;
  margin-top: 2.5rem;
  text-transform: uppercase;
  white-space: nowrap;
`
