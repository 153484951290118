import React from 'react'
import styled from '@emotion/styled'

import { colors, sizes } from '../styles/variables'

const ContentFooter: React.FC = () => (
  <Container>
    <dl>
      <dt>
        お問い合わせ
        <br />
        <span>〈小売店様への卸販売を行なっております〉</span>
      </dt>
      <dd>
        <a href="mailto:info@shitsurindo.com">info@shitsurindo.com</a>
      </dd>
    </dl>
  </Container>
)

export default ContentFooter

const Container = styled.footer`
  background: ${colors.black5};
  padding: 3em 0;
  text-align: center;
  white-space: nowrap;

  dl {
    margin: 0;
  }

  dt {
    color: ${colors.black50};
  }

  dt span {
    font-size: ${sizes.smaller1}em;
  }

  dd {
    color: ${colors.black75};
    margin: 0.5em 0 0;
  }

  a {
    color: inherit;
    font-size: ${sizes.larger2}em;
    text-decoration: none;
    transition: color 0.25s;

    &:hover {
      color: ${colors.black};
    }
  }
`
