import React from 'react'
import styled from '@emotion/styled'

import { colors, sizes, widths } from '../styles/variables'

interface HeadingProps {
  category: string
}

const Heading: React.FC<HeadingProps> = ({ category, children }) => (
  <Container>
    <Subtitle>{category}</Subtitle>
    <Title>{children}</Title>
  </Container>
)

export default Heading

const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  white-space: nowrap;

  @media (min-width: ${widths.mobileLarge}px) {
    flex-direction: row;
  }

  @media (min-width: ${widths.mobileDesktop}px) {
    flex-direction: column;
  }

  @media (min-width: ${widths.desktopLarge}px) {
    flex-direction: row;
  }
`

const Subtitle = styled.div`
  color: ${colors.black25};
  font-size: ${sizes.smaller1}rem;
  text-transform: uppercase;
`

const Title = styled.h2`
  font-size: ${sizes.larger2}rem;
  font-weight: inherit;
  margin: 0;

  @media (min-width: ${widths.mobileLarge}px) {
    font-size: ${sizes.larger3}rem;
  }

  @media (min-width: ${widths.mobileDesktop}px) {
    font-size: ${sizes.larger2}rem;
  }

  @media (min-width: ${widths.desktopLarge}px) {
    font-size: ${sizes.larger3}rem;
  }
`
