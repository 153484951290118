import React from 'react'
import styled from '@emotion/styled'

import { colors, borderRadius } from '../styles/variables'

interface ProductPlaceholderProps {
  onImageClick: () => void
}

const ProductPlaceholder: React.FC<ProductPlaceholderProps> = ({
  onImageClick
}) => <Container onClick={onImageClick} />

const Container = styled.div`
  position: relative;

  &::before {
    background: linear-gradient(
      45deg,
      ${colors.productPlaceholderBottomLeft},
      ${colors.productPlaceholderTopRight}
    );
    border-radius: ${borderRadius}rem;
    content: '';
    display: block;
    padding-top: 100%;
  }

  &::after {
    background: ${colors.black5};
    border-radius: 100px;
    bottom: 0;
    content: '';
    display: block;
    height: 2%;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 0;
    transform: rotate(-45deg);
    width: 20%;
  }
`

export default ProductPlaceholder
