import React, { useEffect, useRef } from 'react'
import styled from '@emotion/styled'

import { animated } from 'react-spring'
import { Form } from '../types'
import { sizes } from '../styles/variables'
import normalizeScrollLeft from '../scripts/normalizeScrollLeft'
import ProductHeaderItem from './ProductHeaderItem'

interface ProductHeaderRowProps {
  forms: Form[]
  scroll: number
  onRequestScroll: (scrollLeft: number, actionType: 'wheel' | 'drag') => void
}

const ProductHeaderRow: React.FC<ProductHeaderRowProps> = ({
  forms,
  scroll,
  onRequestScroll
}) => {
  const listRef = useRef<HTMLUListElement>(null)

  useEffect(() => {
    const onListWheel = (e: WheelEvent) => {
      if (!e.deltaX) return

      e.preventDefault()
      if (listRef.current) {
        onRequestScroll(
          normalizeScrollLeft(
            listRef.current,
            listRef.current.scrollLeft + e.deltaX * 1.5
          ),
          'wheel'
        )
      }
    }
    listRef.current &&
      listRef.current.addEventListener('wheel', onListWheel, {
        passive: false
      })
    return () => {
      listRef.current &&
        listRef.current.removeEventListener('wheel', onListWheel)
    }
  }, [scroll])

  return (
    <Container>
      <animated.ul ref={listRef} scrollLeft={scroll}>
        {forms.map(form => (
          <li key={form.slug}>
            <ProductHeaderItem form={form} />
          </li>
        ))}
      </animated.ul>
    </Container>
  )
}

export default ProductHeaderRow

const Container = styled.div`
  display: flex;
  height: 3em;
  justify-content: center;
  overflow: hidden;

  > ul {
    box-sizing: border-box;
    display: flex;
    height: calc(100% + 3em);
    margin: 0;
    max-width: 100%;
    overflow-x: auto;
    padding: 0 0 0 1em;
  }

  > ul::after {
    content: '';
    display: block;
    flex-shrink: 0;
    width: 1em;
  }

  > ul > li {
    flex-shrink: 0;
    height: 3em;
    list-style: none;
    width: ${sizes.item}em;
  }

  > ul > li + li {
    margin-left: 1em;
  }
`
