import React from 'react'
import GatsbyImage, { FluidObject } from 'gatsby-image'
import styled from '@emotion/styled'

import { colors, borderRadius, sizes, widths } from '../styles/variables'

interface SummaryItemProps {
  image: {
    id: string
    childImageSharp: {
      fluid: FluidObject
    }
  }
  title: string
}

const SummaryItem: React.FC<SummaryItemProps> = ({
  image,
  title,
  children
}) => (
  <Container>
    <Image>
      <GatsbyImage fluid={image.childImageSharp.fluid} />
    </Image>
    <h1>{title}</h1>
    <Children>{children}</Children>
  </Container>
)

export default SummaryItem

const Container = styled.section`
  display: flex;
  flex-wrap: wrap;

  h1 {
    flex-shrink: 0;
    font-size: ${sizes.larger1}em;
    font-weight: inherit;
    margin: ${sizes.smaller1}em 0 0;
    white-space: nowrap;
    width: 100%;
  }

  @media (min-width: ${widths.mobileLarge}px) {
    h1 {
      width: calc(50% - 0.5em);
    }
  }

  @media (min-width: ${widths.mobileDesktop}px) {
    h1 {
      width: 100%;
    }
  }

  @media (min-width: ${widths.desktopLarge}px) {
    h1 {
      width: calc(50% - 0.5em);
    }
  }
`

const Image = styled.div`
  background: ${colors.black5};
  border-radius: ${borderRadius}rem;
  overflow: hidden;
  position: relative;
  width: 100%;
  z-index: 1;
`

const Children = styled.div`
  display: flex;
  flex-shrink: 0;
  font-size: ${sizes.smaller1}em;
  margin-top: ${sizes.larger1}em;
  white-space: nowrap;
  width: 100%;

  @media (min-width: ${widths.mobileLarge}px) {
    justify-content: flex-end;
    margin-left: 1em;
    width: calc(50% - 0.5em);
  }

  @media (min-width: ${widths.mobileDesktop}px) {
    justify-content: initial;
    margin-left: 0;
    width: 100%;
  }

  @media (min-width: ${widths.desktopLarge}px) {
    justify-content: flex-end;
    margin-left: 1em;
    width: calc(50% - 0.5em);
  }
`
