import React, { useState, useEffect } from 'react'
import { animated } from 'react-spring'
import classNames from 'classnames'
import styled from '@emotion/styled'
import useResizeObserver from 'use-resize-observer/polyfilled'

import { durations, colors, sizes } from '../styles/variables'
import { Product } from '../types'
import ArrowEast from '../images/icons/tipArrowEast.svg'
import ArrowWest from '../images/icons/tipArrowWest.svg'
import normalizeScrollLeft from '../scripts/normalizeScrollLeft'
import ProductItem from './ProductItem'
import ProductPlaceholder from './ProductPlaceholder'
import useRemSize from '../scripts/useRemSize'
import useScrollEdge from '../scripts/useScrollEdge'

interface ProductRowProps {
  products: (Product | null)[]
  scroll: number
  onRequestScroll: (
    scrollLeft: number,
    actionType: 'wheel' | 'button' | 'drag'
  ) => void
}

const ProductRow: React.FC<ProductRowProps> = ({
  products,
  scroll,
  onRequestScroll
}) => {
  const [expanded, setExpanded] = useState<boolean>(false)
  const {ref: listRef, height: listHeight} = useResizeObserver()
  const [edgeLeft, edgeRight, edgeOnScroll] = useScrollEdge(listRef)
  const rem = useRemSize()
  const thumbHeight = rem * sizes.item
  const itemInterval = rem * (sizes.item + 1)
  const scrollStops = products.map((_, i) => i * itemInterval)

  useEffect(() => {
    const onListWheel = (e: WheelEvent) => {
      if (!e.deltaX) return

      e.preventDefault()
      onRequestScroll(
        normalizeScrollLeft(
          listRef.current,
          listRef.current.scrollLeft + e.deltaX * 1.5
        ),
        'wheel'
      )
    }
    listRef.current.addEventListener('wheel', onListWheel, { passive: false })
    return () => listRef.current.removeEventListener('wheel', onListWheel)
  }, [scroll])

  const onPrevClick = () => {
    let requestingScrollLeft = scrollStops
      .slice()
      .reverse()
      .find(scrollStop => scrollStop < listRef.current.scrollLeft - 10)
    if (requestingScrollLeft === undefined) {
      onRequestScroll(0, 'button')
    } else {
      onRequestScroll(
        normalizeScrollLeft(listRef.current, requestingScrollLeft),
        'button'
      )
    }
  }

  const onNextClick = () => {
    let requestingScrollLeft = scrollStops.find(
      scrollStop => scrollStop > listRef.current.scrollLeft + 10
    )
    if (requestingScrollLeft === undefined) {
      onRequestScroll(
        listRef.current.scrollWidth - listRef.current.clientWidth,
        'button'
      )
    } else {
      onRequestScroll(
        normalizeScrollLeft(listRef.current, requestingScrollLeft),
        'button'
      )
    }
  }

  useEffect(() => {
    let startX = 0
    let startY = 0
    let startScrollLeft = 0

    const onTouchStart = (e: TouchEvent) => {
      startX = e.touches[0].clientX
      startY = e.touches[0].clientY
      startScrollLeft = listRef.current.scrollLeft
    }

    const onFirstTouchMove = (e: TouchEvent) => {
      const { clientX: x, clientY: y } = e.touches[0]

      if (e.cancelable && Math.abs(startX - x) > Math.abs(startY - y)) {
        e.preventDefault()
      }

      onTouchMove(e)
      listRef.current.removeEventListener('touchmove', onFirstTouchMove)
      listRef.current.addEventListener('touchmove', onTouchMove)
    }

    const onTouchMove = (e: TouchEvent) => {
      const offsetX = (startX - e.touches[0].clientX) * 1.5
      onRequestScroll(
        normalizeScrollLeft(listRef.current, startScrollLeft + offsetX),
        'drag'
      )
    }

    const onTouchEnd = () => {
      listRef.current.removeEventListener('touchmove', onTouchMove)
      listRef.current.addEventListener('touchmove', onFirstTouchMove, {
        passive: false
      })
    }

    listRef.current.addEventListener('touchstart', onTouchStart)
    listRef.current.addEventListener('touchmove', onFirstTouchMove, {
      passive: false
    })
    listRef.current.addEventListener('touchend', onTouchEnd)
    listRef.current.addEventListener('touchcancel', onTouchEnd)

    return () => {
      listRef.current.removeEventListener('touchstart', onTouchStart)
      listRef.current.removeEventListener('touchmove', onFirstTouchMove)
      listRef.current.removeEventListener('touchmove', onTouchMove)
      listRef.current.removeEventListener('touchend', onTouchEnd)
      listRef.current.removeEventListener('touchcancel', onTouchEnd)
    }
  }, [])

  return (
    <Container
      className={classNames({ isExpanded: expanded })}
      style={
        expanded
          ? { height: `${listHeight}px` }
          : { height: `${thumbHeight}px` }
      }
    >
      <animated.ul ref={listRef} scrollLeft={scroll} onScroll={edgeOnScroll}>
        {products.map((product, i) => (
          <li key={product ? `${product.form}-${i}` : `placeholder-${i}`}>
            {product ? (
              <ProductItem
                product={product}
                onImageClick={() => setExpanded(expanded => !expanded)}
              />
            ) : (
              <ProductPlaceholder
                onImageClick={() => setExpanded(expanded => !expanded)}
              />
            )}
          </li>
        ))}
      </animated.ul>
      <PrevButton
        onClick={onPrevClick}
        style={edgeLeft ? { pointerEvents: 'none' } : { pointerEvents: 'auto' }}
      >
        <ArrowWest style={edgeLeft ? { opacity: 0 } : { opacity: 1 }} />
      </PrevButton>
      <NextButton
        onClick={onNextClick}
        style={
          edgeRight ? { pointerEvents: 'none' } : { pointerEvents: 'auto' }
        }
      >
        <ArrowEast style={edgeRight ? { opacity: 0 } : { opacity: 1 }} />
      </NextButton>
    </Container>
  )
}

export default ProductRow

const Button = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
  font: inherit;
  height: 4em;
  outline: none;
  position: absolute;
  top: calc(${sizes.item}em / 2 - 4em / 2);
  width: 4em;

  svg {
    fill: ${colors.black25};
    height: 100%;
    transition: fill ${durations.normal}s, opacity ${durations.normal}s;
    width: 100%;
  }

  &:hover svg {
    fill: ${colors.black75};
  }
`

const PrevButton = styled(Button)`
  left: 0;
`

const NextButton = styled(Button)`
  right: 0;
`

const Container = styled.div`
  align-items: flex-start;
  display: flex;
  justify-content: center;
  overflow: hidden;
  position: relative;
  transition: height ${durations.short}s cubic-bezier(0.5, 0, 0.25, 1),
    padding-bottom ${durations.short}s cubic-bezier(0.5, 0, 0.25, 1);
  width: 100%;

  &.isExpanded {
    padding-bottom: 2em;
  }

  > ul {
    box-sizing: border-box;
    display: flex;
    flex-shrink: 0;
    margin: 0;
    max-width: 100%;
    overflow-x: auto;
    padding: 0 0 3em 1em;
  }

  > ul::after {
    content: '';
    display: block;
    flex-shrink: 0;
    width: 1em;
  }

  > ul > li {
    flex-shrink: 0;
    list-style: none;
    width: ${sizes.item}em;
  }

  > ul > li + li {
    margin-left: 1em;
  }

  ${PrevButton}, ${NextButton} {
    display: none;
  }

  @media (hover: hover) {
    ${PrevButton}, ${NextButton} {
      display: block;
      opacity: 0;
      transition: opacity ${durations.normal}s;
    }

    &:hover ${PrevButton}, &:hover ${NextButton} {
      opacity: 1;
    }
  }
`
