import React from 'react'
import styled from '@emotion/styled'

import { Form } from '../types'
import { sizes, fontFamilies, colors } from '../styles/variables'

interface ProductHeaderItemProps {
  form: Form
}

const ProductHeaderItem: React.FC<ProductHeaderItemProps> = ({ form }) => (
  <Container>
    <span>{form.name}</span>
  </Container>
)

export default ProductHeaderItem

const Container = styled.div`
  align-items: center;
  color: ${colors.black25};
  display: flex;
  font-family: ${fontFamilies.sans};
  height: 3em;
  justify-content: center;
  white-space: nowrap;

  span {
    font-size: ${sizes.smaller1}em;
  }
`
