import { useState, useLayoutEffect } from 'react'

const useRemSize = () => {
  const [rem, setRem] = useState(0)

  useLayoutEffect(() => {
    const updateRem = () => {
      setRem(parseFloat(getComputedStyle(document.documentElement).fontSize))
    }
    addEventListener('resize', updateRem)
    updateRem()
    return () => removeEventListener('resize', updateRem)
  }, [])

  return rem
}

export default useRemSize
