const normalizeScrollLeft = (
  element: Element,
  requestingScrollLeft: number
) => {
  const maxScrollLeft = element.scrollWidth - element.clientWidth
  let scrollLeft = requestingScrollLeft
  scrollLeft = Math.min(scrollLeft, maxScrollLeft)
  scrollLeft = Math.max(scrollLeft, 0)
  return scrollLeft
}

export default normalizeScrollLeft
