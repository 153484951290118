import { RefObject, useState, useEffect } from 'react'

const useScrollEdge = (
  ref: RefObject<Element>
): [boolean, boolean, () => void] => {
  const [edgeLeft, setScrollStart] = useState<boolean>(true)
  const [edgeRight, setScrollEnd] = useState<boolean>(true)

  const onScroll = () => {
    const scrollLeft = (ref.current as Element).scrollLeft
    const scrollWidth = (ref.current as Element).scrollWidth
    const clientWidth = (ref.current as Element).clientWidth
    setScrollStart(scrollLeft <= 0)
    setScrollEnd(scrollLeft + clientWidth >= scrollWidth)
  }

  useEffect(onScroll, [])

  return [edgeLeft, edgeRight, onScroll]
}

export default useScrollEdge
