import React, { useEffect, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import circulate from '@hanamura/circulate'
import Img, { FluidObject } from 'gatsby-image'
import styled from '@emotion/styled'
import useResizeObserver from 'use-resize-observer/polyfilled'

import { borderRadius, widths, sizes } from '../styles/variables'
import useRemSize from '../scripts/useRemSize'

type GalleryImage = {
  id: string
  childImageSharp: {
    fluid: FluidObject
  }
}

interface GalleryProps {
  images: GalleryImage[]
}

const Gallery: React.FC<GalleryProps> = ({ images }) => {
  const rem = useRemSize()
  const { ref, width } = useResizeObserver()

  const isMobileLarge = useMediaQuery({
    query: `(min-width: ${widths.mobileLarge}px)`
  })
  const isMobileDesktop = useMediaQuery({
    query: `(min-width: ${widths.mobileDesktop}px)`
  })
  const isDesktopLarge = useMediaQuery({
    query: `(min-width: ${widths.desktopLarge}px)`
  })

  const [itemWidth, setItemWidth] = useState<number>(0)

  useEffect(() => {
    const update = () => {
      let itemWidth = window.innerWidth - rem * 8
      if (isMobileLarge) itemWidth = rem * 25
      if (isMobileDesktop) itemWidth = rem * 20
      if (isDesktopLarge) itemWidth = rem * 25
      setItemWidth(itemWidth)
    }
    addEventListener('resize', update)
    update()
    return () => removeEventListener('resize', update)
  }, [rem, isMobileLarge, isMobileDesktop, isDesktopLarge])

  let loopedImages: GalleryImage[] = []
  if (!width || !itemWidth) {
    loopedImages.push(...images)
  } else {
    const minImageLength = Math.ceil(width / itemWidth) + 1
    while (loopedImages.length < minImageLength) loopedImages.push(...images)
  }

  const [listHeight, setListHeight] = useState<number>(0)

  useEffect(() => {
    const item = ref.current.firstElementChild.firstElementChild
    if (item) setListHeight(item.offsetHeight)
  }, [width])

  const [position, setPosition] = useState<number>(0)

  useEffect(() => {
    let id: number
    const update = () => {
      setPosition(position => position + 0.0005)
      id = requestAnimationFrame(update)
    }
    update()
    return () => cancelAnimationFrame(id)
  }, [])

  return (
    <Container ref={ref}>
      <ul style={{ height: `${listHeight}px` }}>
        {loopedImages.map((image, i) => {
          let x = itemWidth * (i - position)
          x = circulate(
            x,
            -itemWidth,
            -itemWidth + itemWidth * loopedImages.length
          )
          return (
            <li
              key={`${image.id}-${i}`}
              style={{ transform: `translateX(${x}px)` }}
            >
              <Img fluid={image.childImageSharp.fluid} />
            </li>
          )
        })}
      </ul>
    </Container>
  )
}

export default Gallery

const Container = styled.div`
  margin-left: auto;
  margin-right: auto;
  width: 100%;

  ul {
    margin: 0;
    overflow: hidden;
    padding: 0;
    position: relative;
  }

  li {
    box-sizing: border-box;
    list-style: none;
    padding: 0 0.1em;
    position: absolute;
    width: calc(100% - 8rem);
  }

  img {
    border-radius: ${borderRadius}rem;
  }

  @media (min-width: ${widths.mobileLarge}px) {
    max-width: calc(100% - 6rem);
    width: ${(sizes.item + 1) * 3 - 1}rem;

    ul {
      border-radius: ${borderRadius}rem;
    }

    li {
      width: 25rem;
    }
  }

  @media (min-width: ${widths.mobileDesktop}px) {
    max-width: initial;
    width: calc(100% - 6rem);

    li {
      width: 20rem;
    }
  }

  @media (min-width: ${widths.desktopLarge}px) {
    max-width: calc(100% - 6rem);
    width: ${(sizes.item + 1) * 3 - 1}rem;

    li {
      width: 25rem;
    }
  }
`
