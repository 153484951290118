import React from 'react'
import styled from '@emotion/styled'
import tinycolor from 'tinycolor2'

import {
  colors,
  borderRadius,
  durations,
  uppercaseOffset
} from '../styles/variables'

interface ButtonProps {
  href: string
  color: string
}

const Button: React.FC<ButtonProps> = ({ href, color, children }) => {
  const tcolor = tinycolor(color)
  const hoverColor = (tcolor.isLight()
    ? tcolor.darken(15)
    : tcolor.brighten(15)
  ).toString()

  return (
    <Container
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      draggable={false}
      normalColor={color}
      hoverColor={hoverColor}
      style={href ? { pointerEvents: 'auto' } : { pointerEvents: 'none' }}
    >
      <span>{children}</span>
    </Container>
  )
}

export default Button

const Container = styled.a<{ normalColor: string; hoverColor: string }>`
  align-items: center;
  background: ${props => props.normalColor};
  border-radius: ${borderRadius}rem;
  color: ${colors.white};
  display: flex;
  height: 3em;
  justify-content: center;
  line-height: 1;
  overflow: hidden;
  text-decoration: none;
  text-transform: uppercase;
  transition: background-color ${durations.normal}s;
  white-space: nowrap;

  @media (hover: hover) {
    &:hover {
      background-color: ${props => props.hoverColor};
    }
  }

  &:active {
    background-color: ${props => props.hoverColor};
  }

  span {
    bottom: -${uppercaseOffset}em;
    position: relative;
  }
`
