import React, { useEffect, useRef, useState } from 'react'
import { useSprings } from 'react-spring'
import styled from '@emotion/styled'
import useResizeObserver from 'use-resize-observer/polyfilled'

import { forms as formsObjects } from '../scripts/productInfo'
import { Product, ProductForm, ProductFinish, ProductSeries } from '../types'
import { sizes } from '../styles/variables'
import ProductHeaderRow from './ProductHeaderRow'
import ProductRow from './ProductRow'
import useRemSize from '../scripts/useRemSize'
import { useInView } from 'react-intersection-observer'

interface ProductTableProps {
  series: ProductSeries
  forms: ProductForm[]
  finishes: ProductFinish[]
  products: Product[]
  className?: string
}

const ProductTable: React.FC<ProductTableProps> = ({
  series,
  forms,
  finishes,
  products,
  className
}) => {
  const rem = useRemSize()
  const { ref, width: containerWidth } = useResizeObserver()
  const [scrollSprings, setScrollSprings] = useSprings(
    finishes.length + 1,
    () => ({
      scrollLeft: 0
    })
  )

  const [listRef, inView] = useInView({ threshold: 0 })
  const first = useRef<boolean>(true)
  const [listInit, setListInit] = useState<boolean>(false)

  useEffect(() => {
    if (!inView) return
    if (!first.current) return

    first.current = false
    const scrollWidth = rem * (sizes.item + 1) * forms.length + rem
    const scrollRange = scrollWidth - containerWidth
    setScrollSprings(springIndex => ({
      scrollLeft: scrollRange / 2,
      config: { friction: 60 + (springIndex as number) * 2.5, tension: 100 }
    }))
    setListInit(true)
  }, [forms.length, rem, containerWidth, inView])

  const entries: [string, Product][] = products
    .filter(product => product.series === series)
    .map(product => [`${product.form}-${product.finish}`, product])
  const productMap = new Map(entries)

  const onRequestScroll = (
    itemIndex: number,
    scrollLeft: number,
    scrollType: 'wheel' | 'drag' | 'button'
  ) => {
    setScrollSprings(springIndex => {
      const distance = Math.abs(itemIndex - springIndex)
      return {
        scrollLeft,
        config: {
          ...(scrollType === 'wheel'
            ? { friction: 20 + distance * 15, tension: 500 }
            : scrollType === 'drag'
            ? { friction: 20 + distance * 10, tension: 200 }
            : { friction: 20 + distance * 10, tension: 150 }),
          clamp: true
        }
      }
    })
  }

  return (
    <Container className={className} ref={ref}>
      <ul ref={listRef} className={listInit ? 'isInit' : ''}>
        <li>
          <ProductHeaderRow
            forms={forms.map(slug => formsObjects[slug])}
            scroll={scrollSprings[0].scrollLeft}
            onRequestScroll={(...props) => onRequestScroll(0, ...props)}
          />
        </li>
        {finishes.map((finish, itemIndex) => {
          const rowProducts = forms.map(
            form => productMap.get(`${form}-${finish}`) || null
          )
          return (
            <li key={finish}>
              <ProductRow
                products={rowProducts}
                scroll={scrollSprings[itemIndex + 1].scrollLeft}
                onRequestScroll={(...props) =>
                  onRequestScroll(itemIndex + 1, ...props)
                }
              />
            </li>
          )
        })}
      </ul>
    </Container>
  )
}

export default ProductTable

const Container = styled.div`
  > ul {
    margin: 0;
    opacity: 0;
    padding: 0;
    transition: opacity 2s;
  }

  > ul.isInit {
    opacity: 1;
  }

  > ul > li {
    list-style: none;
  }

  > ul > li + li {
    margin-top: 1em;
  }
`
