import React from 'react'
import Img from 'gatsby-image'
import styled from '@emotion/styled'

import {
  borderRadius,
  sizes,
  lineHeight,
  colors,
  durations,
  fontFamilies
} from '../styles/variables'
import { forms, finishes, series } from '../scripts/productInfo'
import { Product } from '../types'
import Button from './Button'

interface ProductItemProps {
  product: Product
  onImageClick: () => void
}

const ProductItem: React.FC<ProductItemProps> = ({ product, onImageClick }) => {
  const formInfo = forms[product.form]
  const finishInfo = finishes[product.finish]
  const seriesInfo = series[product.series]

  return (
    <Container>
      <Image onClick={onImageClick}>
        <Img
          fixed={product.image.childImageSharp.fixed}
          style={{
            height: '100%',
            position: 'absolute',
            top: 0,
            width: '100%'
          }}
          draggable={false}
        />
      </Image>
      <Detail>
        <ul>
          <li>
            <strong>{formInfo.name}</strong>
          </li>
          {finishInfo.name && (
            <li>
              <strong>{finishInfo.name}</strong>
            </li>
          )}

          {/* price */}
          <li>価格：{product.price}</li>

          {/* size */}
          {product.size ? (
            <li>サイズ：{product.size}</li>
          ) : formInfo.size ? (
            <li>サイズ：{formInfo.size}</li>
          ) : null}

          {/* volume */}
          {formInfo.volume ? <li>容量：{formInfo.volume}</li> : null}

          {/* material */}
          {product.material ? (
            <li>素材：{product.material}</li>
          ) : seriesInfo.material ? (
            <li>素材：{seriesInfo.material}</li>
          ) : null}
        </ul>
      </Detail>
      <ButtonWrapper>
        <Button href={product.retail} color={finishInfo.color}>
          {product.retail ? 'Buy Online' : 'Store Coming Soon'}
        </Button>
      </ButtonWrapper>
    </Container>
  )
}

export default ProductItem

const Container = styled.div``

const Image = styled.div`
  background: ${colors.black5};
  position: relative;
  transition: filter ${durations.normal}s, transform ${durations.short}s;

  &::before {
    content: '';
    display: block;
    padding-top: 100%;
  }

  img {
    border-radius: ${borderRadius}rem;
    display: block;
    height: 100%;
    width: 100%;
  }

  @media (hover: hover) {
    &:hover {
      filter: brightness(90%);
    }
  }

  &:active {
    transform: scale(0.95);
  }
`

const Detail = styled.div`
  color: ${colors.black50};
  font-family: ${fontFamilies.sans};
  font-feature-settings: 'halt';
  font-size: ${sizes.smaller1}em;
  height: ${lineHeight.normal * 3}em;
  margin-top: 0.5em;
  overflow: hidden;
  word-break: break-all;

  ul {
    display: inline;
    margin: 0;
    padding: 0;
  }

  li {
    display: inline;
    list-style: none;
  }

  li + li::before {
    color: ${colors.black25};
    content: '／';
  }
`

const ButtonWrapper = styled.div`
  font-size: ${sizes.smaller1}em;
  margin-top: 0.5em;
`
