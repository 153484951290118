import {
  ProductForm,
  ProductFinish,
  Form,
  ProductSeries,
  Series,
  Finish
} from '../types'

export const forms: { [key in ProductForm]: Form } = {
  ds: {
    slug: 'ds',
    name: '深S',
    size: 'φ118×H61mm',
    volume: 340
  },
  dm: {
    slug: 'dm',
    name: '深M',
    size: 'φ159×H59mm',
    volume: 690
  },
  dl: {
    slug: 'dl',
    name: '深L',
    size: 'φ199×H57mm',
    volume: 1100
  },
  t: {
    slug: 't',
    name: 'トレー丸S',
    size: 'φ87×H30mm',
    volume: 130
  },
  w: {
    slug: 'w',
    name: '椀S',
    size: 'φ103×H55mm',
    volume: 300
  },
  f: {
    slug: 'f',
    name: '平S',
    size: 'φ123×H20mm',
    volume: 140
  },
  fm: {
    slug: 'fm',
    name: '平M',
    size: 'φ193×H21mm',
    volume: 400
  },
  fl: {
    slug: 'fl',
    name: '平L',
    size: 'φ238×H22mm',
    volume: 650
  },
  fw: {
    slug: 'fw',
    name: '平椀S',
    size: 'φ145×H48mm',
    volume: 420
  },
  'meshiwan-s': {
    slug: 'meshiwan-s',
    name: '飯椀S',
    size: 'φ111×H52mm',
    volume: 250
  },
  'meshiwan-m': {
    slug: 'meshiwan-m',
    name: '飯椀M',
    size: 'φ125×H56mm',
    volume: 325
  },
  'sumikiribon-s': {
    slug: 'sumikiribon-s',
    name: '隅切り盆 小',
    size: 'W305×D180×H17mm',
    volume: 0
  },
  'sumikiribon-l': {
    slug: 'sumikiribon-l',
    name: '隅切り盆 大',
    size: 'W420×D305×H16mm',
    volume: 0
  },
  '8sun-marubon': {
    slug: '8sun-marubon',
    name: '8寸丸盆',
    size: 'φ240×H18mm',
    volume: 0
  },
  '9sun-marubon': {
    slug: '9sun-marubon',
    name: '9寸丸盆',
    size: 'φ270×H18mm',
    volume: 0
  },
  'hashi-shikaku': {
    slug: 'hashi-shikaku',
    name: '箸 四角',
    size: '',
    volume: 0
  },
  'hashi-hakkaku': {
    slug: 'hashi-hakkaku',
    name: '箸 八角',
    size: '',
    volume: 0
  }
}

export const finishes: { [key in ProductFinish]: Finish } = {
  b: {
    slug: 'b',
    name: 'BK',
    color: '#222'
  },
  r3: {
    slug: 'r3',
    name: 'R-03',
    color: '#b14944'
  },
  r2: {
    slug: 'r2',
    name: 'R-02',
    color: '#d18282'
  },
  r1: {
    slug: 'r1',
    name: 'R-01',
    color: '#d6abab'
  },
  i3: {
    slug: 'i3',
    name: 'I-03',
    color: '#4a5560'
  },
  i2: {
    slug: 'i2',
    name: 'I-02',
    color: '#5e7b97'
  },
  i1: {
    slug: 'i1',
    name: 'I-01',
    color: '#6d8a9f'
  },
  n3: {
    slug: 'n3',
    name: 'N-03',
    color: '#6d9aa2'
  },
  n2: {
    slug: 'n2',
    name: 'N-02',
    color: '#84acac'
  },
  n1: {
    slug: 'n1',
    name: 'N-01',
    color: '#a4a89e'
  },
  k: {
    slug: 'k',
    name: '',
    color: '#aaa'
  },
  mokko: {
    slug: 'mokko',
    name: '',
    color: '#a38f66'
  }
}

export const series: { [key in ProductSeries]: Series } = {
  primary: {
    slug: 'primary',
    name: '硬漆シリーズ',
    material: '本漆手塗り・木合'
  },
  kutani: {
    slug: 'kutani',
    name: '白九谷シリーズ',
    material: '九谷焼・白磁'
  },
  mokko: {
    slug: 'mokko',
    name: '越前木工',
    material: ''
  }
}
